import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import APIConfig from "../Config/APIConfig";
import clsx from "clsx";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
const JobApplied = ({ updateUser }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drawerHandle, setDrawerHandle] = useState("");
  const [candidateData, setCandidateData] = useState();
  const [downloadResume, setDownloadResume] = useState();
  const [candidateDrawerData, setCandidateDrawerData] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [experience, setExperience] = useState();
  const [qualification, setQualification] = useState();
  const [location, setLocation] = useState();
  const [expected, setExpected] = useState();
  const [current, setCurrent] = useState();
  const [notice, setNotice] = useState();
  const [resume, setResume] = useState("");
  // const [name, setName] = useState();

  const handleDrawerOpen = (val) => {
    setName(val?.name);
    setEmail(val?.email);
    setMobile(val?.phone_number);
    setExperience(val?.experience);
    setQualification(val?.qualification);
    setLocation(val?.location);
    setExpected(val?.expected_ctc);
    setCurrent(val?.current_ctc);
    setNotice(val?.notice_period);
    // setResume(val?.upload_resume);

    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setCandidateDrawerData();
  };
  const handlegetCandidateData = async () => {
    try {
      const res = await Axios.get(APIConfig.getCandidateList);
      if (res.status == 200) {
        setCandidateData(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handlegetCandidateData();
  }, []);
  const handleResumeDownload = () => {
    toast.success("Resume Downloaded");
  };
  const handleDrawerResumeDownload = () => {
    setDownloadResume(candidateDrawerData?.upload_resume);
    toast.success(`${candidateDrawerData?.name} Resume Downloaded`);
  };
  const handleEditCandidateData = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post(APIConfig.updateCandidate);
      if (res.status == 200) {
        toast.success("Candidate Updated Successfully");
      }
    } catch (error) {
      toast.error("Please try again later!");
      console.log(error);
    }
  };
  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <ToastContainer />

      <div id="main">
        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Candidate Applications</h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group">
                    <label for="first-name-column">Search</label>
                    <input
                      type="text"
                      id="date-column"
                      className="form-control"
                      placeholder="Search here..."
                      // name="page_title"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group">
                    <label for="first-name-column">From</label>
                    <input
                      type="date"
                      id="date-column"
                      className="form-control"
                      placeholder="mm/dd/yyyy"
                      // name="page_title"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="form-group">
                    <label for="first-name-column">To</label>
                    <input
                      type="date"
                      id="date-column"
                      className="form-control"
                      placeholder="mm/dd/yyyy"
                      // name="page_title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table
              className="table bg-white rounded shadow-sm table-hover"
              style={{ minWidth: "1440px" }}
            >
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Experience</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Location</th>
                  <th scope="col">Current CTC</th>
                  <th scope="col">Expected CTC</th>
                  <th scope="col">Notice Period</th>
                  <th scope="col">Resume</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {candidateData &&
                  candidateData.map((val, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{val.name}</td>
                        <td>{val.email}</td>
                        <td>{val.phone_number}</td>
                        <td>{val.experience}</td>
                        <td>{val.qualification}</td>
                        <td>{val.location}</td>
                        <td>{val.current_ctc}</td>
                        <td>{val.expected_ctc}</td>
                        <td>{val.notice_period}</td>
                        <td>
                          {val.upload_resume !== "" ? (
                            <span
                              onClick={() => handleResumeDownload()}
                              className={clsx(
                                classes.menuButton,
                                open && classes.hide
                              )}
                            >
                              <i class="fa-sharp fa-solid fa-file-arrow-down"></i>
                            </span>
                          ) : (
                            "No Resume Found"
                          )}
                        </td>
                        <td style={{ display: "flex" }}>
                          {/* <span
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => {
                              handleDrawerOpen(val);
                              setDrawerHandle("Edit");
                            }}
                            edge="start"
                            className={clsx(
                              classes.menuButton,
                              open && classes.hide
                            )}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </span> */}
                          <span
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => {
                              handleDrawerOpen(val);
                              setDrawerHandle("View");
                            }}
                            edge="start"
                            className={clsx(
                              classes.menuButton,
                              open && classes.hide
                            )}
                          >
                            <i className="fa-solid fa-eye"></i>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Candidate ID</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? true
                      : null
                  }
                  id="first-name-column"
                  // onChange={handleEditCandidate}
                  value={candidateDrawerData?.id}
                  className="form-control"
                  placeholder="Enter Page title"
                  name="id"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Name</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Email</label>
                <input
                  type="email"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Mobile Number</label>
                <input
                  type="number"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Experience</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setExperience(e.target.value)}
                  value={experience}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Qualification</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setQualification(e.target.value)}
                  value={qualification}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Location</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Current CTC</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setCurrent(e.target.value)}
                  value={current}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Expected CTC</label>
                <input
                  type="text"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  onChange={(e) => setExpected(e.target.value)}
                  value={expected}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Notice Period</label>
                <input
                  type="number"
                  id="last-name-column"
                  className="form-control"
                  placeholder="Enter Email"
                  readOnly={
                    drawerHandle === "View"
                      ? true
                      : drawerHandle === "Edit"
                      ? false
                      : null
                  }
                  onChange={(e) => setNotice(e.target.value)}
                  value={notice}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  Candidate Resume
                </label>
                {drawerHandle === "View" ? (
                  <div
                    onClick={handleDrawerResumeDownload}
                    className="btn btn-primary"
                  >
                    Download Resume
                  </div>
                ) : drawerHandle === "Edit" ? (
                  <input
                    className="form-control"
                    type="file"
                    // id="formFile"
                    accept="pdf/*"
                    name="page_image"
                    onChange={(e) => {
                      setResume(e.target.files[0].name);
                    }}
                    defaultValue={resume}
                  />
                ) : null}
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end">
              {drawerHandle === "Edit" ? (
                <button
                  // onClick={handleEditCandidateData}
                  type="submit"
                  className="btn btn-primary me-1 mb-1"
                >
                  Submit
                </button>
              ) : null}

              {/* <button
                type="reset"
                className="btn btn-light-secondary me-1 mb-1"
              >
                Reset
              </button> */}
            </div>
          </div>
        </form>
      </Drawer>

      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
          <div className="float-end" style={{ right: "50px" }}>
            <p>
              Crafted with{" "}
              <span className="text-danger">
                <i className="bi bi-heart"></i>
              </span>{" "}
              by <a href="http://Rechargkit.com">Rechargkit</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default JobApplied;

// const [candidateEditData, setCandidateEditData] = useState({
//   current_ctc: candidateDrawerData?.current_ctc,
//   email: candidateDrawerData?.email,
//   expected_ctc: candidateDrawerData?.expected_ctc,
//   experience: candidateDrawerData?.experience,
//   id: candidateDrawerData?.id,
//   location: candidateDrawerData?.location,
//   name: candidateDrawerData?.name,
//   notice_period: candidateDrawerData?.notice_period,
//   phone_number: candidateDrawerData?.phone_number,
//   qualification: candidateDrawerData?.qualification,
//   upload_resume: candidateDrawerData?.upload_resume,
// });
