import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import Footer from "./Footer";
import APIConfig from "../Config/APIConfig";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const ChannelPartner = ({ updateUser }) => {
  const classes = useStyles();
  const [selectType, setSelectType] = useState("All");
  const [channel_partner, setChannel_Partner] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState();
  const [drawerData, setDrawerData] = useState();
  const getChannel_Partner = async () => {
    try {
      const res = await axios.get(APIConfig.getbusinessPart);
      if (res.status == 200) {
        setChannel_Partner(res?.data?.msg);
        setFilterData(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleallData = () => {
    setFilterData(channel_partner);
  };
  const handlewhitepaper = () => {
    setFilterData(channel_partner.filter((values) => values.apply_type === 2));
  };

  const handleapiusers = () => {
    setFilterData(channel_partner.filter((values) => values.apply_type === 1));
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleUsersView = (val) => {
    setOpen(true);
    setDrawerData(val);
  };
  useEffect(() => {
    getChannel_Partner();
  }, []);

  // handleallData(channel_partner);

  return (
    <div id="app">
      <div id="main">
        <SideMenu updateUser={updateUser} />
        <ToastContainer />

        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Channel Partner</h4>
                </div>
              </div>
              <div className="col-12 d-flex mb-3 justify-content-center">
                <button
                  className={
                    selectType === "All"
                      ? "btn btn-primary me-5 mb-1"
                      : "btn btn-light-secondary me-5 mb-1"
                  }
                  onClick={() => {
                    setSelectType("All");
                    handleallData();
                  }}
                >
                  <b>All Channel Partner</b>
                </button>
                <button
                  className={
                    selectType === "White Label"
                      ? "btn btn-primary me-5 mb-1"
                      : "btn btn-light-secondary me-5 mb-1"
                  }
                  onClick={() => {
                    setSelectType("White Label");
                    handlewhitepaper();
                  }}
                >
                  <b>White Label</b>
                </button>
                <button
                  onClick={() => {
                    setSelectType("API Users");
                    handleapiusers();
                  }}
                  className={
                    selectType === "API Users"
                      ? "btn btn-primary me-5 mb-1"
                      : "btn btn-light-secondary me-5 mb-1"
                  }
                >
                  <b>API Users</b>
                </button>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table
              style={{ minWidth: "1280px" }}
              className="table bg-white rounded shadow-sm table-hover"
            >
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Business Type</th>
                  <th scope="col">Owner Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Owner Email</th>
                  <th scope="col">Business Name</th>
                  <th scope="col">Business Address</th>
                  <th scope="col">Current Business</th>
                  <th scope="col">Current Turnover</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {filterData &&
                  filterData.map((val, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {val?.apply_type == 1
                            ? "API Users"
                            : val?.apply_type == 2
                            ? "White Label"
                            : null}
                        </td>
                        <td>{val?.b_name}</td>
                        <td>{val?.b_mobile}</td>
                        <td>{val?.b_email}</td>
                        <td>{val?.b_organization}</td>
                        <td>{val.b_address}</td>
                        <td>{val?.current_business}</td>
                        <td>{val?.current_turnover}</td>

                        <td
                          color="inherit"
                          aria-label="open drawer"
                          onClick={() => handleUsersView(val)}
                          edge="start"
                          className={clsx(
                            classes.menuButton,
                            open && classes.hide
                          )}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Business Type</label>
                <input
                  type="text"
                  readOnly
                  value={
                    drawerData?.apply_type == 1
                      ? "API Users"
                      : drawerData?.apply_type == 2
                      ? "White Label"
                      : null
                  }
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                  name="id"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Owner Name</label>
                <input
                  type="text"
                  readOnly
                  value={drawerData?.b_name}
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Owner Email</label>
                <input
                  type="email"
                  readOnly
                  value={drawerData?.b_email}
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Contact Number</label>
                <input
                  type="number"
                  readOnly
                  value={drawerData?.b_mobile}
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Business Name</label>
                <input
                  type="text"
                  readOnly
                  value={drawerData?.b_organization}
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Business Address</label>
                <input
                  type="text"
                  readOnly
                  value={drawerData?.b_address}
                  id="first-name-column"
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Current Business</label>
                <input
                  type="text"
                  value={drawerData?.current_business}
                  id="first-name-column"
                  className="form-control"
                  readOnly
                  placeholder="Enter Page title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Current Business Turnover</label>
                <input
                  type="text"
                  readOnly
                  id="first-name-column"
                  value={drawerData?.current_turnover}
                  className="form-control"
                  placeholder="Enter Page title"
                />
              </div>
            </div>
          </div>
        </form>
      </Drawer>
      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <Footer />
      </footer>
    </div>
  );
};

export default ChannelPartner;
