import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";
// import Image from './Image'

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Product = ({ updateUser }) => {
  const classes = useStyles();
  const history = useNavigate();
  const [user, setUser] = useState({
    p_category_id: "",
    product_title: "",
    product_image: "",
    product_model: "",
    product_short_desc: "",
    product_long_desc: "",
    p_status: "",
  });

  const [product, setProduct] = useState([]);
  const [open, setOpen] = useState(false);

  const [counter, setCounter] = useState(0);

  const getProduct = () => {
    axios.get(APIConfig.getProduct).then((res) => {
      const myProduct = res.data.result;
      setProduct(myProduct);
    });
  };
  useEffect(() => {
    getProduct();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setUser({ ...user, [name]: value });
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleImage = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  console.log(file);
  console.log(fileName);

  const PostData = async (e) => {
    e.preventDefault();
    // console.log(productImage);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    // formData.append("productCategory", ProductCategory);
    // formData.append("productTitle", ProductTitle);
    // formData.append("productModel", ProductModel);
    // formData.append("productShortDescription", ProductShortDescription);
    // formData.append("productShortDescription", ProductShortDescription);

    // console.log(productImage);
    // console.log(imageName);

    const {
      p_category_id,
      product_title,
      product_model,
      product_short_desc,
      product_long_desc,
    } = user;

    if (
      p_category_id &&
      product_title &&
      product_model &&
      product_short_desc &&
      product_long_desc
    ) {
      formData.append("p_category_id", p_category_id);
      formData.append("product_title", product_title);
      formData.append("product_model", product_model);
      formData.append("product_short_desc", product_short_desc);
      formData.append("product_long_desc", product_long_desc);

      const res = await axios.post(APIConfig.insertProduct, formData);
      console.log(res);

      // const res =  fetch("http://localhost:5000/api/insertproducts", {
      //     method: "POST",
      //     headers:
      //     {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json'
      //     },
      //     // body: JSON.stringify({
      //     //     p_category_id, product_title, "file":fileName, product_model, product_short_desc, product_long_desc
      //     // })
      //     body:formData
      // }).then((response)=>{ // response.json()).then((response)=>{
      //     console.log(response)
      // }).catch(e=>{
      //     console.log(e);
      // });
      // console.log('Result '+(await res).json());

      // const data =  res.json(res);

      // console.log(data)

      if (res.status === 200) {
        toast.success("Product data inserted successfully", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getProduct();
      } else {
        toast.error("Product data inserted failed", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Fill all the fields");
    }
  };

  const [id, setId] = useState("");
  const [ProductCategory, setProductCategory] = useState("");
  const [ProductTitle, setProductTitle] = useState("");
  const [ProductModel, setProductModel] = useState("");
  const [ProductShortDescription, setProductShortDescription] = useState("");
  const [ProductLongDescription, setProductLongDescription] = useState("");
  const [ProductStatus, setProductStatus] = useState("");

  const handleDrawerOpen = (val) => {
    setOpen(true);
    // console.log(val.product_id)
    setId(val.product_id);
    setProductCategory(val.p_category_id);
    setProductTitle(val.product_title);
    // setFile(val.product_image);
    setProductModel(val.product_model);
    setProductShortDescription(val.product_short_desc);
    setProductLongDescription(val.product_long_desc);
    setProductStatus(val.p_status);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenView(false);
  };

  const handleProductEdit = async (e) => {
    e.preventDefault();

    const res = await fetch(APIConfig.updateProduct, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id: id,
        p_category_id: ProductCategory,
        product_title: ProductTitle,
        product_image: file,
        product_model: ProductModel,
        product_short_desc: ProductShortDescription,
        product_long_desc: ProductLongDescription,
        p_status: ProductStatus,
      }),
    });

    const data = await res.json();

    if (data.status === "error") {
      toast.error(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      getProduct();
      setOpen();
      toast.success(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [openView, setOpenView] = useState(false);
  const handleUsersView = (val) => {
    setOpenView(true);
  };

  // const uploadedImage = React.useRef(null);
  // const imageUploader = React.useRef(null);

  // const handleImageUpload = e => {
  //     const [file] = e.target.files;
  //     if (file) {
  //         const reader = new FileReader();
  //         const { current } = uploadedImage;
  //         current.file = file;
  //         reader.onload = e => {
  //             current.src = e.target.result;
  //         };
  //         reader.readAsDataURL(file);
  //     }
  // };

  //     var categorylist =
  // { value:1,
  // label: "Accessories"
  // },
  // { value:0,
  // label: "TV"
  // }

  // const[result, categoryValue]= useState(categorylist.value);
  // const categoryHandler = e =>
  // {
  // categoryValue(e.value);
  // }

  // console.log(p_category_id)

  // const [category, setCategory] = useState(false)
  // const toggleCategory = () => {
  //     setCategory(!category);
  // }

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <div id="main">
        {/* <Image/> */}
        <ToastContainer />

        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Add Product</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="first-name-column">
                              Product Category
                            </label>{" "}
                            &nbsp;
                            {/* <select type="text" id="first-name-column" className="form-control"
                                                            value={user.p_category_id}
                                                            placeholder="Enter Product Category" name="p_category_id"> 

                                                                <option value="Accessories">Accessories</option>
                                                                <option value="TV">TV</option>
                                                            </select>     */}
                            <div className="col-xl-6 form-group">
                              <select
                                className="col-xl-6 form-group"
                                name="p_category_id"
                                id="first-name-column"
                                value={user.p_category_id}
                                onChange={handleInputs}
                              >
                                <option value="category">Category</option>
                                <option value="1">Smart TV</option>
                                <option value="2">Accessories</option>
                                <option value="3">Geyser</option>
                                <option value="4">Chimney</option>
                              </select>
                            </div>
                            {/* <select type="text" id="first-name-column" className="form-control"                                                               
                                                                placeholder="Enter product category" 
                                                                value={user.p_category_id}
                                                                onChange={handleInputs}name="p_category_id"> &nbsp;

                                                                    <option value="smart tv">Smart TV</option>
                                                                    <option value="accessories">Accessories</option>
                                                                    <option value="geyser">Geyser</option>
                                                                    <option value="chimney">Chimney</option>
                                                            </select> */}
                            {/* <input type="text" id="first-name-column" className="form-control"
                                                            value={user.p_category_id}
                                                            onChange={handleInputs}
                                                            placeholder="Enter Product Category" name="p_category_id" /> */}
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">Product Title</label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.product_title}
                              onChange={handleInputs}
                              placeholder="Enter product title"
                              name="product_title"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              Product Image
                            </label>{" "}
                            &nbsp;
                            <input
                              className="image-resize-filepond"
                              accept="image/*"
                              type="file"
                              name="file"
                              onChange={handleImage}
                            />
                          </div>
                          {/* <div
                                                        style={{
                                                        height: "60px",
                                                        width: "110px",
                                                        border: "1px dashed black"
                                                        }}
                                                        onClick={() => imageUploader.current.click()}
                                                    >
                                                        <img
                                                        ref={uploadedImage}
                                                        style={{
                                                            width: "10%",
                                                            height: "10%",
                                                            position: "absolute"
                                                        }}
                                                        /> 
                                                    </div> */}
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">Product Model</label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.product_model}
                              onChange={handleInputs}
                              placeholder="Enter product model"
                              name="product_model"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">
                              {" "}
                              Product Short Description
                            </label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.product_short_desc}
                              onChange={handleInputs}
                              placeholder="Enter product short description"
                              name="product_short_desc"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">
                              Product Long Description
                            </label>
                            <input
                              type="text"
                              id="last-name-column"
                              className="form-control"
                              value={user.product_long_desc}
                              onChange={handleInputs}
                              placeholder="Enter product long description"
                              name="product_long_desc"
                            />
                          </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-1 mb-1"
                            onClick={PostData}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn btn-light-secondary me-1 mb-1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table bg-white rounded shadow-sm table-hover">
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Product Category</th>
                  <th scope="col">Product Title</th>
                  <th scope="col">Product Image</th>
                  <th scope="col">Product Model</th>
                  <th scope="col">Product Short Description</th>
                  <th scope="col">Product Long Description</th>
                  {/* <th scope="col">Product Status</th> */}
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {product.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.p_category_id}</td>
                      <td>{val.product_title}</td>
                      <td>
                        {/* {
                          <img
                            style={{ width: "100px" }}
                            src={
                              "http://localhost:5000/getImage?image=" +
                              val.product_image
                            }
                            alt=""
                          />
                        } */}

                        <img
                          src={`${APIConfig.productImage}${val.product_image}`}
                          alt="Product Image"
                          width="100%"
                        />
                      </td>
                      <td>{val.product_model}</td>
                      <td>{val.product_short_desc}</td>
                      <td>{val.product_long_desc}</td>
                      {/* <td>{val.p_status}</td> */}
                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleUsersView(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Product Category</label>

                <input
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  value={ProductCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  placeholder="Enter Product Category"
                  name="p_category_id"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Product Title</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={ProductTitle}
                  onChange={(e) => setProductTitle(e.target.value)}
                  placeholder="Enter Product Title"
                  name="product_title"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  Product Image
                </label>
                <input
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  type="file"
                  id="formFile"
                  name="product_image "
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Product Model</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={ProductModel}
                  onChange={(e) => setProductModel(e.target.value)}
                  placeholder="Enter Product Model"
                  name="contact_link"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Product Short Description</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={ProductShortDescription}
                  onChange={(e) => setProductShortDescription(e.target.value)}
                  placeholder="Enter Product Short Description"
                  name="contact_link"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Product Long Description</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={ProductLongDescription}
                  onChange={(e) => setProductLongDescription(e.target.value)}
                  placeholder="Enter Product Long Description"
                  name="contact_link"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Product Status</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={ProductStatus}
                  onChange={(e) => setProductStatus(e.target.value)}
                  placeholder="Enter Product Status"
                  name="contact_link"
                />
              </div>
            </div>

            <div className="form-group col-12">
              <div className="form-check">
                {/* <!-- <div className="checkbox">
                                <input type="checkbox" id="checkbox5" className='form-check-input'
                                    checked>
                                <label for="checkbox5">Remember Me</label>
                            </div> --> */}
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-1 mb-1"
                onClick={handleProductEdit}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-light-secondary me-1 mb-1"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </Drawer>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openView}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              //href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form method="GET container m-5">
          <div className="row">
            <div className="col-md-6">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Category: </label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductCategory}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Title:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductTitle}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Image:</label>
                    </div>
                    <div className="col-md-6">
                      <p></p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Model:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductModel}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Short Description:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductShortDescription}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Long Description:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductLongDescription}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Status:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ProductStatus}</p>
                    </div>
                  </div>

                  {/* <div className="row">
                                        <div className="col-md-6">
                                            <label>Member Image</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{MemberImage}</p>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Drawer>

      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
          <div className="float-end" style={{ right: "50px" }}>
            <p>
              Crafted with{" "}
              <span className="text-danger">
                <i className="bi bi-heart"></i>
              </span>{" "}
              by <a href="http://Rechargkit.com">Rechargkit</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Product;
