import React from "react";
import { NavLink, useLocation } from "react-router-dom";
const SideMenu = ({ updateUser }) => {
  // console.log(updateUser)
  const location = useLocation();
  const { pathname } = location;
  const SplitLocation = pathname.split("/");
  const Logout = () => {
    updateUser({});
  };
  return (
    <div id="sidebar" className="active">
      <div className="sidebar-wrapper active">
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <NavLink to="index.html">
                <img
                  src="assets/images/logo/RechargeLogo.png"
                  alt="Logo"
                  style={{ height: "100px", width: "200px" }}
                />
              </NavLink>
            </div>
            <div className="toggler">
              <NavLink to="#" className="sidebar-hide d-xl-none d-block">
                <i className="bi bi-x bi-middle"></i>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <>
              <li
                className={
                  SplitLocation[1] === ""
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/" className="sidebar-link ">
                  <i className="bi bi-grid-fill"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "aboutus"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/aboutus" className="sidebar-link">
                  <i className="bi bi-stack"></i>
                  <span>AboutUs</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "career"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/career" className="sidebar-link">
                  <i className="bi bi-collection-fill"></i>
                  <span>Career</span>
                </NavLink>
              </li>
              <li
                className={
                  SplitLocation[1] === "channalpartner"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/channalpartner" className="sidebar-link">
                  <i className="bi bi-collection-fill"></i>
                  <span>Channel Partners</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "product"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/product" className="sidebar-link">
                  <i className="bi bi-grid-1x2-fill"></i>
                  <span>Product</span>
                </NavLink>
              </li>

              {/* <!-- <li className="sidebar-title">Forms &amp; Tables</li> --> */}

              <li
                className={
                  SplitLocation[1] === "jobapplied"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/jobapplied" className="sidebar-link">
                  <i className="bi bi-hexagon-fill"></i>
                  <span>Candidate Applications</span>
                </NavLink>
              </li>
              <li
                className={
                  SplitLocation[1] === "slider"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/slider" className="sidebar-link">
                  <i className="bi bi-hexagon-fill"></i>
                  <span>Slider</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "team"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/team" className="sidebar-link">
                  <i className="bi bi-file-earmark-medical-fill"></i>
                  <span>Team</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "ourgallery"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/ourgallery" className="sidebar-link">
                  <i className="bi bi-file-earmark-medical-fill"></i>
                  <span>Gallery Images</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "ourevents"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/ourevents" className="sidebar-link">
                  <i className="bi bi-file-earmark-medical-fill"></i>
                  <span>Events Images</span>
                </NavLink>
              </li>

              <li
                className={
                  SplitLocation[1] === "media"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink to="/media" className="sidebar-link">
                  <i className="bi bi-file-earmark-medical-fill"></i>
                  <span>Media</span>
                </NavLink>
              </li>
            </>

            <li
              className={
                SplitLocation[1] === "user"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <NavLink to="/user" className="sidebar-link">
                <i className="fa-solid fa-user"></i>
                <span>User</span>
              </NavLink>
            </li>

            <li
              className={
                SplitLocation[1] === "logout"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <NavLink onClick={Logout} to="/login" className="sidebar-link">
                <i className="fa fa-sign-out"></i>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x"></i>
        </button>
      </div>
      <header className="mb-3">
        <div
          style={{
            backgroundColor: "#428bca",
            height: "80px",
            paddingTop: "20px",
          }}
        >
          <div
            className="avatar bg-warning avatar-lg me-3"
            style={{ float: "right " }}
          >
            <span className="avatar-content">SA</span>
            <span className="avatar-status bg-success"></span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default SideMenu;
