import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Team = ({ updateUser }) => {
  const classes = useStyles();
  const history = useNavigate();
  const [user, setUser] = useState({
    member_name: "",
    designation: "",
    member_image: "",
    contact_link: "",
  });

  const [ourteam, setOurteam] = useState([]);
  const [open, setOpen] = useState(false);

  const getOurteam = () => {
    axios.get(APIConfig.getOurTeam).then((res) => {
      const myOurteam = res.data.result;
      setOurteam(myOurteam);
    });
  };
  useEffect(() => {
    getOurteam();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setUser({ ...user, [name]: value });
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleImage = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  console.log(file);
  console.log(fileName);

  const PostData = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    const { member_name, designation, contact_link } = user;

    if (member_name && designation && contact_link) {
      formData.append("member_name", member_name);
      formData.append("designation", designation);
      // formData.append("member_image", member_image);
      formData.append("contact_link", contact_link);

      const res = await axios.post(APIConfig.insertOurTeam, formData);
      console.log(res);

      if (res.status === 200) {
        toast.success("Team data inserted successfully", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getOurteam();
      } else {
        toast.error("Team data inserted failed", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Fill all the fields");
    }
  };

  //         const res = await fetch("http://localhost:5000/api/insertourteam", {
  //             method: "POST",
  //             headers:
  //             {
  //                 'Accept': 'application/json',
  //                 'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify({
  //                 member_name, designation, member_image, contact_link,
  //             })
  //         });

  //         const data = await res.json();
  //         if (data.status === 400 || !data) {
  //             toast.error("Something Went Wrong")

  //         }
  //         else {
  //             toast.success(data.msg)
  //             getOurteam();
  //         }
  //     }
  //     else {
  //         toast.error("Fill all the fields")
  //     }
  // }

  const [id, setId] = useState("");
  const [MemberName, setMemberName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [SocialLink, setSocialLink] = useState("");
  const [MemberImage, setMemberImage] = useState("");

  const handleDrawerOpen = (val) => {
    setOpen(true);
    // console.log(val.member_id)
    setId(val.member_id);
    setMemberName(val.member_name);
    setDesignation(val.designation);
    setSocialLink(val.contact_link);
    setMemberImage(val.member_image);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenView(false);
  };

  const handleOurteamEdit = async (e) => {
    e.preventDefault();

    const res = await fetch(APIConfig.updateOurTeam, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        member_id: id,
        member_name: MemberName,
        designation: Designation,
        contact_link: SocialLink,
        member_image: MemberImage,
      }),
    });

    const data = await res.json();

    if (data.status === "error") {
      toast.error(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      getOurteam();
      setOpen();
      toast.success(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [openView, setOpenView] = useState(false);
  const handleUsersView = (val) => {
    setOpenView(true);
    setMemberName(val.member_name);
    setDesignation(val.designation);
    setSocialLink(val.contact_link);
    setMemberImage(val.member_image);
  };

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <div id="main">
        <ToastContainer />

        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Add Team</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="first-name-column">Member Name </label>
                            <input
                              type="text"
                              id="first-name-column"
                              className="form-control"
                              value={user.member_name}
                              onChange={handleInputs}
                              placeholder="Enter Member Name"
                              name="member_name"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">Designation</label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.designation}
                              onChange={handleInputs}
                              placeholder="Enter Designation"
                              name="designation"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">Social Link</label>
                            <input
                              type="email"
                              id="last-name-column"
                              className="form-control"
                              value={user.contact_link}
                              onChange={handleInputs}
                              placeholder="Enter Social Link"
                              name="contact_link"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              Member Image
                            </label>
                            <input
                              className="image-resize-filepond"
                              accept="image/*"
                              type="file"
                              name="file"
                              onChange={handleImage}
                            />
                          </div>
                        </div>

                        {/* <label for="formFileSm" className="form-label">Small file input example</label>
                                            <input className="form-control form-control-sm" id="formFileSm" type="file"></input> */}

                        <div className="form-group col-12">
                          <div className="form-check">
                            {/* <!-- <div className="checkbox">
                                                        <input type="checkbox" id="checkbox5" className='form-check-input'
                                                            checked>
                                                        <label for="checkbox5">Remember Me</label>
                                                    </div> --> */}
                          </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-1 mb-1"
                            onClick={PostData}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn btn-light-secondary me-1 mb-1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table bg-white rounded shadow-sm table-hover">
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Member Name</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Member Image</th>
                  <th scope="col">Social Link</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {ourteam.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.member_name}</td>
                      <td>{val.designation}</td>
                      <td>
                        {
                          <img
                            style={{ width: "100px" }}
                            src={APIConfig.teamImage + val.member_image}
                            alt=""
                          />
                        }
                      </td>
                      <td>{val.contact_link}</td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleUsersView(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              //href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column">Member Name </label>
                <input
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  value={MemberName}
                  onChange={(e) => setMemberName(e.target.value)}
                  placeholder="Enter Member Name"
                  name="member_name"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Designation</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={Designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  placeholder="Enter Designation"
                  name="designation"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Social Link</label>
                <input
                  type="email"
                  id="last-name-column"
                  className="form-control"
                  value={SocialLink}
                  onChange={(e) => setSocialLink(e.target.value)}
                  placeholder="Enter Social Link"
                  name="contact_link"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  Member Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  name="member_image"
                />
              </div>
            </div>

            <div className="form-group col-12">
              <div className="form-check">
                {/* <!-- <div className="checkbox">
                                                        <input type="checkbox" id="checkbox5" className='form-check-input'
                                                            checked>
                                                        <label for="checkbox5">Remember Me</label>
                                                    </div> --> */}
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-1 mb-1"
                onClick={handleOurteamEdit}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-light-secondary me-1 mb-1"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </Drawer>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openView}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form method=" container">
          <div className="row">
            <div className="col-md-6">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Member Name: </label>
                    </div>
                    <div className="col-md-6">
                      <p>{MemberName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Designation:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{Designation}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Social Link:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{SocialLink}</p>
                    </div>
                  </div>
                  {/* <div className="row">
                                        <div className="col-md-6">
                                            <label>Member Image</label>
                                        </div>
                                        <div className="col-md-6">
                                            <p>{MemberImage}</p>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Drawer>

      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
          <div className="float-end" style={{ right: "50px" }}>
            <p>
              Crafted with{" "}
              <span className="text-danger">
                <i className="bi bi-heart"></i>
              </span>{" "}
              by <a href="http://Rechargkit.com">Rechargkit</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Team;
