import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIConfig from "../Config/APIConfig";

const Slider = ({ updateUser }) => {
  const history = useNavigate();
  const [user, setuser] = useState({
    slider_id: "",
    slider_image: "",
    slider_status: "",
  });

  const [imageName, setImageName] = useState("");
  const [sliderImage, setSliderImage] = useState(null);
  const [sliderStatus, setSliderStatus] = useState();
  // const [previewSource, setPreviewSource] = useState("");

  //  const data =[{"image":"backend.PNG"},{"image":"test2"}];
  //  const listItems = data.map((d) => <li key={d.name}>{d.name}</li>);

  const handleImage = (e) => {
    setSliderImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  // const previewFile = (file) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //        setPreviewSource(reader.result)
  //     }
  // }

  const [slider, setSlider] = useState([]);
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);

  // const getSliderImages = () => {
  //   axios.get(APIConfig.getSliderImage).then((res) => {
  //     // console.log(res)
  //     const mySlider = res.data.url;
  //     console.log(mySlider);
  //     setSliderImages(mySlider);
  //   });
  // };

  // useEffect(() => {
  //   getSliderImages();
  // }, []);

  const getSlider = () => {
    axios.get(APIConfig.getSlider).then((res) => {
      const mySlider = res.data.data;
      console.log(mySlider);
      setSlider(mySlider);
    });
  };

  useEffect(() => {
    getSlider();
  }, [counter]);

  const changeSliderStatus = async (val) => {
    let { slider_status, slider_id } = val;
    if (slider_status === 1) {
      slider_status = 0;
    } else {
      slider_status = 1;
    }
    console.log(slider_status);

    await fetch(APIConfig.status, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slider_status,
        slider_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (slider_status === 0) {
          setSliderStatus(slider_status);
          toast.success(data.msg, {
            position: "top-right",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCounter(counter + 1);
        } else {
          setSliderStatus(slider_status);
          toast.success(data.msg, {
            position: "top-right",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCounter(counter + 1);
        }
      });
  };

  const handleSlider = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", sliderImage);
    formData.append("fileName", imageName);

    try {
      const res = await axios.post(APIConfig.insertSlider, formData);
      if (res.status === 200) {
        toast.success("Image Uploaded Successfully", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("image upload failed", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  };

  const deleteSlider = async (val) => {
    let { slider_id } = val;
    await fetch(APIConfig.deleteSlider, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slider_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.type === "success") {
          toast.success(data.msg, {
            position: "bottom-right",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getSlider();
        } else {
          toast.error("Slider Deleted failed", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <div id="main">
        <ToastContainer />

        <section className="section">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Upload Slider Here</h5>
                </div>

                <div className="card-content">
                  <div className="card-body">
                    <input
                      type="file"
                      className="image-resize-filepond"
                      accept="image/*"
                      onChange={handleImage}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={handleSlider}
                    type="submit"
                    className="btn btn-primary me-1 mb-1"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light-secondary me-1 mb-1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="table-responsive">
          <table className="table bg-white rounded shadow-sm table-hover">
            <thead className="fixed">
              <tr>
                <th scope="col">Serial Number</th>
                <th scope="col">Slider Image</th>

                <th scope="col">Slider Status</th>
                <th scope="col">Action</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {slider.map((val, index) => {
                return (
                  <tr key={index}>
                    <td>{val.slider_id}</td>
                    <td>
                      {
                        <img
                          style={{ width: "200px" }}
                          src={APIConfig.aboutImage + val.slider_image}
                          alt=""
                        />
                      }
                    </td>
                    <td>{val.slider_status}</td>

                    <td>
                      {" "}
                      {val.slider_status === 1 ? (
                        <a onClick={() => changeSliderStatus(val)}>
                          <i
                            style={{ color: "green" }}
                            className="fas fa-check-circle"
                          ></i>
                        </a>
                      ) : (
                        <a onClick={() => changeSliderStatus(val)}>
                          <i
                            style={{ color: "red" }}
                            className="fas fa-times-circle"
                          ></i>
                        </a>
                      )}
                    </td>

                    <td>
                      <a
                        className="text-danger"
                        onClick={() => deleteSlider(val)}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* {
                        previewSource && (
                            <img src={previewSource} alt='' style={{height:"300px"}}/>
                        )
                    } */}
        </div>
      </div>

      <footer
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: " rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Slider;
