import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { UserContext } from "./components/context";
import Dashboard from "./components/Dashboard";
import AboutUs from "./components/AboutUs";
import Career from "./components/Career";
import Slider from "./components/Slider";
import Team from "./components/Team";
import User from "./components/User";
import Product from "./components/Product";
import OurEvents from "./components/OurEvents";
import OurGallery from "./components/OurGallery";
import Media from "./components/Media";
import Login from "./components/Login";
import SideMenu from "./components/SideMenu";

import "./App.css";
import JobApplied from "./components/JobApplied";
import ChannelPartner from "./components/ChannelPartner";

function App() {
  const [user, setLoginUser] = useState({});
  useEffect(() => {
    setLoginUser(JSON.parse(localStorage.getItem("refab_admin")));
  }, []);

  const updateUser = (user) => {
    localStorage.setItem("refab_admin", JSON.stringify(user));
    setLoginUser(user);
  };
  console.log(user);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              user && user.user_id ? (
                <Dashboard updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />

          <Route
            path="/aboutus"
            element={
              user && user.user_id ? (
                <AboutUs updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/career"
            element={
              user && user.user_id ? (
                <Career updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/slider"
            element={
              user && user.user_id ? (
                <Slider updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/team"
            element={
              user && user.user_id ? (
                <Team updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/channalpartner"
            element={
              user && user.user_id ? (
                <ChannelPartner updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/user"
            element={
              user && user.user_id ? (
                <User updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/product"
            element={
              user && user.user_id ? (
                <Product updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/jobapplied"
            element={
              user && user.user_id ? (
                <JobApplied updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/ourgallery"
            element={
              user && user.user_id ? (
                <OurGallery updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/ourevents"
            element={
              user && user.user_id ? (
                <OurEvents updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route
            path="/media"
            element={
              user && user.user_id ? (
                <Media updateUser={updateUser} />
              ) : (
                <Login updateUser={updateUser} />
              )
            }
          />
          <Route path="/login" element={<Login updateUser={updateUser} />} />
          <Route element={<SideMenu updateUser={updateUser} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
