import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Media = ({ updateUser }) => {
  const classes = useStyles();

  const [user, setuser] = useState({
    media_id: "",
    media_name: "",
    media_link: "",
  });
  const [media, setMedia] = useState([]);
  const [open, setOpen] = useState(false);

  const getMedia = () => {
    axios.get(APIConfig.getMedia).then((res) => {
      console.log(res);
      const myMedia = res.data.msg;
      setMedia(myMedia);
    });
  };
  useEffect(() => {
    getMedia();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setuser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();

    const { media_name, media_link } = user;

    if ((media_name, media_link)) {
      const res = await fetch(APIConfig.insertMedia, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          media_name,
          media_link,
        }),
      });

      const data = await res.json();

      if (data.status === 400 || !data) {
        toast.error("Something Went Wrong");
      } else {
        toast.success(data.msg);
        getMedia();
      }
    } else {
      toast.error("Fill All The Fields");
    }
  };

  const [id, setId] = useState("");
  const [MediaName, setMediaName] = useState("");
  const [MediaLink, setMediaLink] = useState("");

  const handleDrawerOpen = (val) => {
    setOpen(true);
    console.log(val.media_id);
    setId(val.media_id);
    setMediaName(val.media_name);
    setMediaLink(val.media_link);
  };

  // const handleDrawerClose = () => {
  //     setOpen(false);
  //     setOpenView(false)
  // }

  return (
    <div>
      <div id="app">
        <SideMenu updateUser={updateUser} />
        <div id="main">
          <ToastContainer />

          {/* <!-- // Basic multiple Column Form section start --> */}
          <section id="multiple-column-form">
            <div className="row match-height">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Add Media</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form className="form" method="POST">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label for="first-name-column">Media Name </label>
                              <input
                                type="text"
                                id="first-name-column"
                                className="form-control"
                                value={user.media_name}
                                onChange={handleInputs}
                                placeholder="Enter media name"
                                name="media_name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label for="last-name-column">
                                Add Video Link
                              </label>
                              <input
                                type="email"
                                id="last-name-column"
                                className="form-control"
                                value={user.media_link}
                                onChange={handleInputs}
                                placeholder="Enter media link"
                                name="media_link"
                              />
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-1 mb-1"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                              onClick={PostData}
                            >
                              Submit
                            </button>

                            <button
                              type="reset"
                              className="btn btn-light-secondary me-1 mb-1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table bg-white rounded shadow-sm table-hover">
                <thead className="fixed">
                  <tr>
                    <th scope="col">Serial Number</th>
                    <th scope="col">Media Name</th>
                    <th scope="col">Media Link</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {media?.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{val.media_name}</td>
                        <td>{val.media_link}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Media;
