import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const User = ({ updateUser }) => {
  const classes = useStyles();
  const history = useNavigate();

  const [user, setuser] = useState({
    user_name: "",
    user_mail_id: "",
    user_mobile_no: "",
    user_password: "",
  });

  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const getUsers = () => {
    axios.get(APIConfig.getUser).then((res) => {
      const myUsers = res.data.result;
      setUsers(myUsers);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setuser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();

    const { user_name, user_mail_id, user_mobile_no, user_password } = user;

    if ((user_name, user_mail_id, user_mobile_no, user_password)) {
      const res = await fetch(APIConfig.userRegister, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_name,
          user_mail_id,
          user_mobile_no,
          user_password,
        }),
      });

      const data = await res.json();

      if (data.status === 400 || !data) {
        toast.error("Something Went Wrong");
      } else {
        toast.success(data.msg);
        getUsers();
      }
    } else {
      toast.error("Fill All The Fields");
    }
  };

  const [id, setId] = useState("");
  const [UserName, setUserName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [MobNo, setMobNo] = useState("");

  const handleDrawerOpen = (val) => {
    setOpen(true);
    console.log(val.user_id);
    setId(val.user_id);
    setUserName(val.user_name);
    setEmail(val.user_mail_id);
    setPassword(val.user_password);
    setMobNo(val.user_mobile_no);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenView(false);
  };

  const handleUsersEdit = async (e) => {
    e.preventDefault();

    const res = await fetch(APIConfig.updateUser, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: id,
        user_name: UserName,
        user_mail_id: Email,
        user_mobile_no: MobNo,
        user_password: Password,
      }),
    });

    const data = await res.json();

    if (data.status === "error") {
      toast.error(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      getUsers();
      setOpen();
      toast.success(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [openView, setOpenView] = useState(false);
  const handleUsersView = (val) => {
    setOpenView(true);
    setUserName(val.user_name);
    setEmail(val.user_mail_id);
    setPassword(val.user_password);
    setMobNo(val.user_mobile_no);
  };

  return (
    <div>
      <div id="app">
        <div id="main">
          <SideMenu updateUser={updateUser} />
          <ToastContainer />

          {/* <!-- // Basic multiple Column Form section start --> */}
          <section id="multiple-column-form">
            <div className="row match-height">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Add User</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form className="form" method="POST">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label for="first-name-column">User Name </label>
                              <input
                                type="text"
                                id="first-name-column"
                                className="form-control"
                                value={user.user_name}
                                onChange={handleInputs}
                                placeholder="Enter Name"
                                name="user_name"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                // id="last-name-column"
                                className="form-control"
                                value={user.user_mail_id}
                                onChange={handleInputs}
                                placeholder="Enter Email"
                                name="user_mail_id"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                // id="last-name-column"
                                className="form-control"
                                value={user.user_password}
                                onChange={handleInputs}
                                placeholder="Enter Email"
                                name="user_password"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>Mob-No.</label>
                              <input
                                type="number"
                                id="country-floating"
                                className="form-control"
                                value={user.user_mobile_no}
                                onChange={handleInputs}
                                name="user_mobile_no"
                                placeholder="Enter Mob Number"
                              />
                            </div>
                          </div>

                          <div className="form-group col-12">
                            <div className="form-check">
                              {/* <!-- <div className="checkbox">
                                                        <input type="checkbox" id="checkbox5" className='form-check-input'
                                                            checked>
                                                        <label for="checkbox5">Remember Me</label>
                                                    </div> --> */}
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-1 mb-1"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                              onClick={PostData}
                            >
                              Submit
                            </button>

                            <button
                              type="reset"
                              className="btn btn-light-secondary me-1 mb-1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table bg-white rounded shadow-sm table-hover">
                <thead className="fixed">
                  <tr>
                    <th scope="col">Serial Number</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">MobNo</th>
                    <th scope="col">Password</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {users.map((val, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{val.user_name}</td>
                        <td>{val.user_mail_id}</td>
                        <td>{val.user_mobile_no}</td>
                        <td>{val.user_password}</td>

                        <td
                          color="inherit"
                          aria-label="open drawer"
                          onClick={() => handleDrawerOpen(val)}
                          edge="start"
                          className={clsx(
                            classes.menuButton,
                            open && classes.hide
                          )}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </td>

                        <td
                          color="inherit"
                          aria-label="open drawer"
                          onClick={() => handleUsersView(val)}
                          edge="start"
                          className={clsx(
                            classes.menuButton,
                            open && classes.hide
                          )}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </div>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className="card-header-right">
              <a
                onClick={handleDrawerClose}
                //href="#"
                className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
              >
                Close
              </a>
            </div>
          </div>

          <form className="form container" method="POST">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label for="first-name-column">User Name </label>
                  <input
                    type="text"
                    id="first-name-column"
                    className="form-control"
                    value={UserName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter Page title"
                    name="user_name"
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    // id="last-name-column"
                    className="form-control"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Email"
                    name="user_mail_id"
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    // id="last-name-column"
                    className="form-control"
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Email"
                    name="user_password"
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>Mob-No.</label>
                  <input
                    type="number"
                    id="country-floating"
                    className="form-control"
                    value={MobNo}
                    onChange={(e) => setMobNo(e.target.value)}
                    name="user_mobile_no"
                    placeholder="Enter Mob Number"
                  />
                </div>
              </div>

              <div className="form-group col-12">
                <div className="form-check">
                  {/* <!-- <div className="checkbox">
                                                        <input type="checkbox" id="checkbox5" className='form-check-input'
                                                            checked>
                                                        <label for="checkbox5">Remember Me</label>
                                                    </div> --> */}
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary me-1 mb-1"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                  onClick={handleUsersEdit}
                >
                  Submit
                </button>
                <button
                  type="reset"
                  className="btn btn-light-secondary me-1 mb-1"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </Drawer>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={openView}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className="card-header-right">
              <a
                onClick={handleDrawerClose}
                //href="#"
                className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
              >
                Close
              </a>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="tab-content profile-tab" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label>User Name:</label>
                      </div>
                      <div className="col-md-6">
                        <p>{UserName}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Email:</label>
                      </div>
                      <div className="col-md-6">
                        <p>{Email}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Password:</label>
                      </div>
                      <div className="col-md-6">
                        <p>{Password}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Mobile No.:</label>
                      </div>
                      <div className="col-md-6">
                        <p>{MobNo}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>

        <footer
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "rgb(237, 236, 236)",
            color: "white",
          }}
        >
          <div className="footer clearfix mb-0 text-muted">
            <div className="float-start">
              <p>2021 &copy; Mazer</p>
            </div>
            <div className="float-end" style={{ right: "50px" }}>
              <p>
                Crafted with{" "}
                <span className="text-danger">
                  <i className="bi bi-heart"></i>
                </span>{" "}
                by <a href="http://Rechargkit.com">Rechargkit</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default User;
