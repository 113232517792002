import { useState, useContext } from "react";
import React from "react";
// import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import APIConfig from "../Config/APIConfig";
// import { UserContext } from "./context";

const Login = ({ updateUser }) => {
  // const { setUser, user } = useContext(UserContext);

  const navigate = useNavigate(); // use to route bw diff components

  const [userDetails, setDetails] = useState({
    user_mail_id: "",
    user_password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  //   handle change

  const handleLoginChange = (e) => {
    const { name, value } = e.target; //object destructuring
    setDetails({ ...userDetails, [name]: value });
  };

  const loginApi = async (e) => {
    console.log("userDetails: ", userDetails);
    e.preventDefault();
    setIsLoading(true);
    const { user_mail_id, user_password } = userDetails;
    if (user_mail_id && user_password) {
      await axios
        .post(APIConfig.login, userDetails)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            toast.success("Login Successfull", {
              position: "top-right",
              theme: "colored",
            });

            updateUser(res.data.user);
            navigate("/");
          } else {
            toast.error(res.data.message, {
              position: "top-right",
              theme: "colored",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Invalid Credential", {
            position: "top-right",
            theme: "colored",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error("Invalid Inputs", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="register-photo">
      <div className="form-container">
        <ToastContainer />

        <div className="image-holder"></div>
        <form>
          <h2 className="text-center">
            <strong>Welcome back!</strong>
          </h2>
          <div className="form-group">
            <input
              className="form-control"
              type="Username"
              placeholder="Username"
              name="user_mail_id"
              onChange={handleLoginChange}
              value={userDetails.user_mail_id}
            />
          </div>

          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Password"
              name="user_password"
              onChange={handleLoginChange}
              value={userDetails.user_password}
            />
          </div>

          <div className="form-group">
            <div className="d-flex justify-content-between">
              <div className="form-check">
                <div className="d-grid gap-5 col-6 mx-auto">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={loginApi}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="form-group"><button className="btn btn-success btn-block btn-info" type="submit">Login</button></div><a className="already" href="#">Terms of Use and Privacy Policy</a> */}
        </form>
      </div>
    </div>
  );
};
export default Login;
