import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core/";
import APIConfig from "../Config/APIConfig";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Career = ({ updateUser }) => {
  const classes = useStyles();
  const history = useNavigate();
  const [user, setUser] = useState({
    job_title: "",
    job_desc: "",
    job_summary: "",
    key_skill: "",
    job_type: "",
    responsibilities_duties: "",
    job_category: "",
    required_experience_qualifications: "",
    job_salary: "",
    job_location: "",
  });

  const [career, setCareer] = useState([]);
  const [open, setOpen] = useState(false);

  const getCareer = () => {
    axios.get(APIConfig.getCareer).then((res) => {
      const myCareer = res.data.result;
      setCareer(myCareer);
    });
  };
  useEffect(() => {
    getCareer();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;

    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();

    const {
      job_title,
      job_desc,
      job_summary,
      key_skill,
      job_type,
      responsibilities_duties,
      job_category,
      required_experience_qualifications,
      job_salary,
      job_location,
    } = user;

    if (
      (job_title,
      job_desc,
      job_summary,
      key_skill,
      job_type,
      responsibilities_duties,
      job_category,
      required_experience_qualifications,
      job_salary,
      job_location)
    ) {
      const res = await fetch(APIConfig.insertCareer, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_title,
          job_desc,
          job_summary,
          key_skill,
          job_type,
          responsibilities_duties,
          job_category,
          required_experience_qualifications,
          job_salary,
          job_location,
        }),
      });

      const data = await res.json();
      if (data.status === 400 || !data) {
        toast.error("Something Went Wrong");
      } else {
        toast.success(data.msg);
        getCareer();
      }
    } else {
      toast.error("Fill All The Fields");
    }
  };

  const [id, setId] = useState("");
  const [JobTitle, setJobTitle] = useState("");
  const [JobDescription, setJobDescription] = useState("");
  const [JobSummary, setJobSummary] = useState("");
  const [KeySkill, setKeySkill] = useState("");
  const [JobType, setJobType] = useState("");
  const [Responsibilities, setResponsibilities] = useState("");
  const [JobCategory, setJobCategory] = useState("");
  const [ExperienceAndQualifications, setExperienceAndQualifications] =
    useState("");
  const [JobSalary, setJobSalary] = useState("");
  const [JobLocation, setJobLocation] = useState("");
  console.log("asdassc0", user);
  const handleDrawerOpen = (val) => {
    setOpen(true);
    console.log(val.job_id);
    setId(val.job_id);
    setJobTitle(val.job_title);
    setJobDescription(val.job_desc);
    setJobSummary(val.job_summary);
    setKeySkill(val.key_skill);
    setJobType(val.job_type);
    setResponsibilities(val.responsibilities_duties);
    setJobCategory(val.job_category);
    setExperienceAndQualifications(val.required_experience_qualifications);
    setJobSalary(val.job_salary);
    setJobLocation(val.job_location);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setOpenView(false);
  };

  const handleCareerEdit = async (e) => {
    e.preventDefault();

    const res = await fetch(APIConfig.updateCareer, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        job_id: id,
        job_title: JobTitle,
        job_desc: JobDescription,
        job_summary: JobSummary,
        key_skill: KeySkill,
        job_type: JobType,
        responsibilities_duties: Responsibilities,
        job_category: JobCategory,
        required_experience_qualifications: ExperienceAndQualifications,
        job_salary: JobSalary,
        job_location: JobLocation,
      }),
    });

    const data = await res.json();

    if (data.status === "error") {
      toast.error(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      getCareer();
      setOpen();
      toast.success(data.msg, {
        position: "bottom-right",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [openView, setOpenView] = useState(false);
  const handleUsersView = (val) => {
    setOpenView(true);
  };

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <div id="main">
        <ToastContainer />
        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Career</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="first-name-column"> Job Title</label>
                            <input
                              type="text"
                              id="first-name-column"
                              className="form-control"
                              value={user.job_title}
                              onChange={handleInputs}
                              placeholder="Enter Page Title"
                              name="job_title"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="last-name-column">
                              Job Description
                            </label>
                            <textarea
                              className="form-control"
                              value={user.job_desc}
                              onChange={handleInputs}
                              placeholder="Enter Job Description"
                              id="floatingTextarea"
                              name="job_desc"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="city-column">Job Summary</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Job Summary"
                              value={user.job_summary}
                              onChange={handleInputs}
                              id="floatingTextarea"
                              name="job_summary"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="country-floating">Key Skill</label>
                            <textarea
                              className="form-control"
                              value={user.key_skill}
                              onChange={handleInputs}
                              placeholder="Enter Skill"
                              id="floatingTextarea"
                              name="key_skill"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label
                              for="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Job Type
                            </label>
                            <select
                              className="form-select"
                              id="basicSelect"
                              name="job_type"
                              onChange={handleInputs}
                              value={user.job_type}
                            >
                              <option>Select</option>
                              <option>Part-Time</option>
                              <option>Full-Time</option>
                              <option>Intern</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              Responsibilities
                            </label>
                            <textarea
                              className="form-control"
                              value={user.responsibilities_duties}
                              onChange={handleInputs}
                              placeholder="Enter Responsibilities"
                              id="floatingTextarea"
                              name="responsibilities_duties"
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              Experience and Qualifications
                            </label>
                            <textarea
                              className="form-control"
                              value={user.required_experience_qualifications}
                              onChange={handleInputs}
                              placeholder="Enter Experience"
                              id="floatingTextarea"
                              name="required_experience_qualifications"
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              Job Salary
                            </label>
                            <div className="input-group mb-3">
                              <span className="input-group-text">$</span>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Amount (to the nearest dollar)"
                                value={user.job_salary}
                                onChange={handleInputs}
                                placeholder="Enter Salary"
                                name="job_salary"
                              />
                              <span className="input-group-text">.00</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              Job Category
                            </label>
                            <input
                              type="text"
                              id="first-name-column"
                              className="form-control"
                              value={user.job_category}
                              onChange={handleInputs}
                              placeholder="Enter Job Category"
                              name="job_category"
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label for="formFile" className="form-label">
                              {" "}
                              Job Location
                            </label>
                            <textarea
                              className="form-control"
                              value={user.job_location}
                              onChange={handleInputs}
                              placeholder="Enter Job Location"
                              id="floatingTextarea"
                              name="job_location"
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-group col-12">
                          <div className="form-check">
                            {/* <!-- <div className="checkbox">
                                                    <input type="checkbox" id="checkbox5" className='form-check-input'
                                                        checked>
                                                    <label for="checkbox5">Remember Me</label>
                                                </div> --> */}
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-1 mb-1"
                            onClick={PostData}
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            className="btn btn-light-secondary me-1 mb-1"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table bg-white rounded shadow-sm table-hover">
              <thead className="fixed">
                <tr>
                  <th scope="col">Serial Number</th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Job Description</th>
                  <th scope="col">Job Summary</th>
                  <th scope="col">Key Skill</th>
                  <th scope="col">Job Type</th>
                  <th scope="col">Responsibilities</th>
                  <th scope="col">Experience and Qualifications</th>
                  <th scope="col">Job Salary</th>
                  <th scope="col">Job Category</th>
                  <th scope="col">Job Location</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {career.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.job_title}</td>
                      <td>{val.job_desc}</td>
                      <td>{val.job_summary}</td>
                      <td>{val.key_skill}</td>
                      <td>{val.job_type}</td>
                      <td>{val.responsibilities_duties}</td>
                      <td>{val.job_category}</td>
                      <td>{val.required_experience_qualifications}</td>
                      <td>{val.job_salary}</td>
                      <td>{val.job_location}</td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </td>

                      <td
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleUsersView(val)}
                        edge="start"
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
        {/* <!-- // Basic multiple Column Form section end --> */}
      </div>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form className="form container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="first-name-column"> Job Title</label>
                <input
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  value={JobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="Enter Page Title"
                  name="job_title"
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="last-name-column">Job Description</label>
                <textarea
                  className="form-control"
                  value={JobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  placeholder="Enter Job Description"
                  id="floatingTextarea"
                  name="job_desc"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="city-column">Job Summary</label>
                <textarea
                  className="form-control"
                  placeholder="Enter Job Summary"
                  value={JobSummary}
                  onChange={(e) => setJobSummary(e.target.value)}
                  id="floatingTextarea"
                  name="job_summary"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="country-floating">Key Skill</label>
                <textarea
                  className="form-control"
                  value={KeySkill}
                  onChange={(e) => setKeySkill(e.target.value)}
                  placeholder="Enter Skill"
                  id="floatingTextarea"
                  name="key_skill"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Job Type
                </label>
                <select
                  className="form-select"
                  id="basicSelect"
                  name="job_type"
                  onChange={(e) => setJobType(e.target.value)}
                  value={JobType}
                >
                  <option>Select</option>
                  <option value="parttime">Part-Time</option>
                  <option value="fulltime">Full-Time</option>
                  <option value="intern">Intern</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  Responsibilities
                </label>
                <textarea
                  className="form-control"
                  value={Responsibilities}
                  onChange={(e) => setResponsibilities(e.target.value)}
                  placeholder="Enter Responsibilities"
                  id="floatingTextarea"
                  name="responsibilities_duties"
                ></textarea>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  Experience and Qualifications
                </label>
                <textarea
                  className="form-control"
                  value={ExperienceAndQualifications}
                  onChange={(e) =>
                    setExperienceAndQualifications(e.target.value)
                  }
                  placeholder="Enter Experience"
                  id="floatingTextarea"
                  name="required_experience_qualifications"
                ></textarea>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  Job Salary
                </label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    value={JobSalary}
                    onChange={(e) => setJobSalary(e.target.value)}
                    placeholder="Enter Salary"
                    name="job_salary"
                  />
                  <span className="input-group-text">.00</span>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  Job Category
                </label>
                <input
                  type="text"
                  id="first-name-column"
                  className="form-control"
                  value={JobCategory}
                  onChange={(e) => setJobCategory(e.target.value)}
                  placeholder="Enter Job Category"
                  name="job_category"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group">
                <label for="formFile" className="form-label">
                  {" "}
                  Job Location
                </label>
                <textarea
                  className="form-control"
                  value={JobLocation}
                  onChange={(e) => setJobLocation(e.target.value)}
                  placeholder="Enter Job Location"
                  id="floatingTextarea"
                  name="job_location"
                ></textarea>
              </div>
            </div>

            <div className="form-group col-12">
              <div className="form-check"></div>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-1 mb-1"
                onClick={handleCareerEdit}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-light-secondary me-1 mb-1"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </Drawer>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openView}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className="card-header-right">
            <a
              onClick={handleDrawerClose}
              //href="#"
              className="collapsed btn btn-sm waves-effect waves-light btn-primary m-0"
            >
              Close
            </a>
          </div>
        </div>

        <form method="GET container">
          <div className="row">
            <div className="col-md-6">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Title:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobTitle}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Description:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobDescription}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Summary:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobSummary}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Key Skill:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{KeySkill}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Type:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobType}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Responsibilities:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{Responsibilities}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Category:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobCategory}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Experience And Qualifications:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{ExperienceAndQualifications}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Salary:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobSalary}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Job Location:</label>
                    </div>
                    <div className="col-md-6">
                      <p>{JobLocation}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Drawer>

      <footer
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
          <div className="float-end" style={{ right: "50px" }}>
            <p>
              Crafted with{" "}
              <span className="text-danger">
                <i className="bi bi-heart"></i>
              </span>{" "}
              by <a href="http://Rechargkit.com">Rechargkit</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Career;
