import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIConfig from "../Config/APIConfig";

const OurGallery = ({ updateUser }) => {
  const history = useNavigate();
  const [user, setuser] = useState({
    gallery_id: "",
    gallery_name: "",
    gallery_image: "",
  });

  const [imageName, setImageName] = useState("");
  const [galleryImage, setGalleryImage] = useState(null);

  const handleImage = (e) => {
    setGalleryImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const [gallery, setGallery] = useState([]);
  // const [galleryImages, setGalleryImages] = useState([])
  const [open, setOpen] = useState(false);

  const getGalleryImage = () => {
    axios.get(APIConfig.getGallery).then((res) => {
      // console.log(res)
      const myGallery = res.data.msg;
      // console.log(myGallery)
      setGallery(myGallery);
    });
  };

  useEffect(() => {
    getGalleryImage();
  }, []);

  const handleGallery = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", galleryImage);
    formData.append("fileName", imageName);

    try {
      const res = await axios.post(APIConfig.insertGallery, formData);
      if (res.status === 200) {
        toast.success("Image Uploaded Successfully", {
          position: "top-right",
          theme: "colored",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Image upload failed", {
          position: "top-right",
          theme: "colored",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div id="app">
      <SideMenu updateUser={updateUser} />
      <div id="main">
        <ToastContainer />

        <section className="section">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Upload Gallery Images Here</h5>
                </div>

                <div className="card-content">
                  <div className="card-body">
                    <input
                      type="file"
                      className="image-resize-filepond"
                      accept="image/*"
                      onChange={handleImage}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary me-1 mb-1"
                    onClick={handleGallery}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light-secondary me-1 mb-1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="table-responsive">
          <table className="table bg-white rounded shadow-sm table-hover">
            <thead className="fixed">
              <tr>
                <th scope="col">Serial Number</th>
                {/* <th scope="col">Event Name</th> */}
                <th scope="col">Gallery Image</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody>
              {gallery.map((val, index) => {
                console.log(val);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {
                        <img
                          style={{ width: "100px" }}
                          src={`${APIConfig.galleryImage}${val.gallery_image}`}
                          alt=""
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <footer
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: " rgb(237, 236, 236)",
          color: "white",
        }}
      >
        <div className="footer clearfix mb-0 text-muted">
          <div className="float-start">
            <p>2021 &copy; Mazer</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default OurGallery;
