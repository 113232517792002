import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="footer clearfix mb-0 text-muted">
        <div className="float-start">
          <p>2021 &copy; Mazer</p>
        </div>
        <div className="float-end" style={{ right: "50px" }}>
          <p>
            Crafted with{" "}
            <span className="text-danger">
              <i className="bi bi-heart"></i>
            </span>{" "}
            by <a href="http://Rechargkit.com">Rechargkit</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
